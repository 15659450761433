<template>
    <div v-loading="loading" element-loading-background="rgba(255, 255, 255, 1)">
        <div v-for="el in list" :key="el.key" class="item">
            <upload-img class="upload" :src="el.image" @removeSrc="() => (el.image = '')" ref="image"></upload-img>
            <el-input style="flex: 1" v-model="el.title" placeholder="链接名字"></el-input>
            <el-input style="flex: 1" v-model="el.url" placeholder="链接"></el-input>
            <el-switch
                class="sw"
                v-model="el.status"
                active-color="#7781f1"
                inactive-color="#ff4949"
                active-text="显示"
                inactive-text="隐藏"
                :active-value="1"
                :inactive-value="0"
            ></el-switch>
            <el-tooltip effect="dark" content="新增一行" placement="top">
                <i class="el-icon-circle-plus-outline" style="font-size: 24px; color: #7781f1" @click="add"></i>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除该行" placement="top">
                <i class="el-icon-remove-outline" style="font-size: 24px; color: #7781f1" @click="del(el.key)"></i>
            </el-tooltip>
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="重新加载" scale="4" @click.native="load"></VyIcon>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import uploadImg from "@/components/customs/uploadImg.vue";
import QueryString from "qs";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { uploadImg, VyIcon },
    data() {
        return {
            list: [],
            loading: false,
        };
    },
    async created() {
        this.load();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/website/bottomlist");
            return data;
        },
        // 添加
        add() {
            this.list.push({
                title: "",
                url: "",
                image: "",
                status: 1,
                key: Math.random(),
            });
        },
        // 删除
        del(key) {
            if (this.list.length == 1) return this.$message.error("请勿删除最后一项");
            this.list = this.list.filter(e => e.key != key);
        },
        // 加载
        async load() {
            this.loading = true;
            this.list = await this.getData();
            // this.list.push({
            //     title: "",
            //     url: "",
            //     image: "",
            // });
            this.list.forEach(e => (e.key = Math.random()));
            this.loading = false;
        },
        // 确认
        async yes() {
            let { list } = this;
            list.forEach((e, i) => {
                e.image = this.$refs.image[i].url;
            });
            let { data } = await axios.post("/api/website/bottomadd", QueryString.stringify({ data: list }));
            this.$message({
                type: data.code == 200 ? "success" : "error",
                message: data.msg,
            });
            this.load();
        },
    },
};
</script>

<style lang="less" scoped>
.item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & > div {
        margin-right: 10px;
    }
    .upload {
        zoom: 0.3;
        /deep/.el-icon-zoom-in {
            display: none;
        }
        /deep/.el-icon-circle-close {
            zoom: 3;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
